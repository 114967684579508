import "../Styles/Profilestyles.css";
import { Image, Col, Row,Button } from "react-bootstrap";
import ProfileImg from "../assets/myimage.jpg";
import { useState } from "react";
import pdf from '../assets/Resume.pdf'
const Profile = () => {
      const [loading, setLoading] = useState(false);

      const handleDownload = () => {
        setLoading(true);
        // Simulating download process for 2 seconds
        setTimeout(() => {
          setLoading(false);
          // Simulate download link click
          const link = document.createElement("a");
          link.href = pdf; // Assuming pdf is defined somewhere
          link.download = "Resume.pdf";
          link.click();
        }, 2000);
      };
  return (
    <>
      <div className="intro mt-5 gap-5">
        <Row className="ms-5">
          <Col lg={3} sm={12} md={12} className="m-5 d-flex justify-content-center">
            <Image
              src={ProfileImg}
              rounded
              width={280}
              height={350}
              className="profilepic"
            />
          </Col>
          <Col lg={9} md={12} style={{ width: "40rem" }}>
            <div className="scroll-reveal">
              <h4 className="mt-5">
                <span>Who am I</span>
              </h4>
              <div></div>
              <h4 style={{ width: "100%" }} className="mt-4">
                <span className="secondele">
                  I'm SHIVA a AWS Cloud Solution Architecture and Web Developer
                </span>
              </h4>
              <p className="">
                <span>
                  Hello Everyone My Name Shiva I'm 20 Years Old(2023) I am
                  Passionate about AWS Cloud, I excel in leveraging cloud
                  technologies to design robust and scalable solutions. My
                  enthusiasm extends to web development, where I craft dynamic
                  and user-centric applications, blending creativity with
                  functionality. In software development, I thrive on writing
                  efficient, clean code that solves complex problems, ensuring a
                  seamless user experience. My commitment to staying at the
                  forefront of technology allows me to adapt and innovate in the
                  ever-evolving landscape of cloud and software development.
                  With a strong foundation in AWS, I am dedicated to driving
                  excellence in cloud and web development projects.
                </span>
              </p>
            </div>
            <Button
              className="btn btn-success"
              onClick={handleDownload}
              disabled={loading}
            >
              {loading ? "Downloading..." : "Download CV"}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Profile;
