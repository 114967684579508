import React from "react";
import img1 from "../assets/img1.jpg";
import img2 from "../assets/img2.jpg";
import img3 from "../assets/img3.jpg";
import { useEffect } from "react";
import "../Styles/App.css";

function Gallery() {
  useEffect(() => {
    function initParallax() {
      var parallaxItems = document.querySelectorAll("[data-bss-parallax]");
      var defaultSpeed = 0.5;
      var visible = [];
      var scheduled;

      function scroll() {
        visible.length = 0;

        for (var i = 0; i < parallaxItems.length; i++) {
          var rect = parallaxItems[i].getBoundingClientRect();
          var speed =
            parseFloat(
              parallaxItems[i].getAttribute("data-bss-parallax-speed"),
              10
            ) || defaultSpeed;

          if (rect.bottom > 0 && rect.top < window.innerHeight) {
            visible.push({
              speed: speed,
              node: parallaxItems[i],
            });
          }
        }

        cancelAnimationFrame(scheduled);

        if (visible.length) {
          scheduled = requestAnimationFrame(update);
        }
      }

      function update() {
        for (var i = 0; i < visible.length; i++) {
          var node = visible[i].node;
          var speed = visible[i].speed;

          node.style.transform =
            "translate3d(0, " + -window.scrollY * speed + "px, 0)";
        }
      }

      window.addEventListener("scroll", scroll);
      window.addEventListener("resize", scroll);

      scroll();
    }

    initParallax();
  }, []); // End of use strict
  return (
    <div className="gallery">
      <div className="col-12 col-lg-10 mx-auto">
        <div
          className="position-relative"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              position: "relative",
              flex: "0 0 45%",
              transform: "translate3d(-22%, 75%, 0)",
            }}
          >
            <a href="https://github.com/SHIVA-6699">
              <img
                className="img-fluid img3"
                id="img1"
                data-bss-parallax
                data-bss-parallax-speed="0.7"
                src={img3} // Use the imported image
                width={200}
                style={{
                  border: "3px solid white",
                  borderRadius: "5px",
                }}
                height={200}
                alt="profile"
              />
            </a>
          </div>
          <div
            style={{
              position: "relative",
              flex: "0 0 45%",
              transform: "translate3d(-20%, 65%, 0)",
            }}
          >
            <a href="https://leetcode.com/SHIVA-6699">
              <img
                className="img-fluid img2"
                data-bss-parallax
                style={{
                  border: "3px solid white",
                  borderRadius: "5px",
                }}
                data-bss-parallax-speed="0.5"
                width={200}
                height={200}
                src={img2} // Use the imported image
                alt=""
              />
            </a>
          </div>
          <div
            style={{
              position: "relative",
              flex: "0 0 60%",
              transform: "translate3d(-20%, 50%, 0)",
            }}
          >
            <a href="https://www.credly.com/users/pilla-veera-durga-siva-krishna.4482c333/badges">
              <img
                className="img-fluid imgs1"
                data-bss-parallax
                data-bss-parallax-speed="0.2"
                width={200}
                height={200}
                style={{
                  border: "3px solid white",
                  borderRadius: "5px",
                }}
                src={img1} // Use the imported image
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
