import { Link } from "react-router-dom";
import { useState } from "react";
import { Button, Image, Row, Col } from "react-bootstrap";
import "../Styles/Projectstyles.css";
import pdf from "../assets/Resume.pdf";
import img1 from "../assets/avatar.jpg";
import img2 from "../assets/ProjectPhot/img1.webp";
import img3 from "../assets/ProjectPhot/img2.png";
import img4 from "../assets/ProjectPhot/img3.webp";
import img5 from "../assets/ProjectPhot/img4.jpg";
import img6 from "../assets/ProjectPhot/img5.png";
import img7 from "../assets/ProjectPhot/img6.jpg";
import img8 from "../assets/ProjectPhot/img7.jpg";

const Projects = () => {
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
      setLoading(true);
      // Simulating download process for 2 seconds
      setTimeout(() => {
        setLoading(false);
        // Simulate download link click
        const link = document.createElement("a");
        link.href = pdf; // Assuming pdf is defined somewhere
        link.download = "Resume.pdf";
        link.click();
      }, 2000);
    };
  const projectsData = [
    {
      img: img2,
      title: "Phone Book",
      description:
        "It is Made Wit C Language And also In Python Using Tkinter GUI Module",
    },
    {
      img: img3,
      title: "INDIA Resource Analysis",
      description:
        "Crafted an interactive dashboard with Dash, Plotly, Pandas, and NumPy, seamlessly integrating dynamic graphs for user engagement. Leveraging Python's powerful libraries, the dashboard offers intuitive data visualization and analysis, enhancing user experience and insights.",
    },
    {
      img: img4,
      title: "Creating Compelling Newspaper Layouts and Posters with Photoshop",
      description:
        "I successfully completed a Photoshop project involving designing a newspaper layout and poster. Utilizing creative skills and attention to detail, I crafted visually captivating designs tailored to the projects requirements, effectively communicating the intended message.",
    },
    {
      img: img5,
      title: "Power Automation Control",
      description:
        "Implemented an IoT-based power automation system, revolutionizing energy management by seamlessly controlling devices remotely. Through cutting-edge technology integration, the project optimizes power usage, enhances efficiency, and fosters sustainable practices for a smarter, greener future.",
    },
    {
      img: img6,
      title: "Learning Management System",
      description:
        "Developed a comprehensive Learning Management System (LMS) using Java, with a user-friendly web interface for seamless accessibility. Offering robust features for course management, assessment, and collaboration, the platform empowers educators and learners alike with efficient tools for academic success.",
    },
    {
      img: img7,
      title: "ATM Management System",
      description:
        "It is developed in java with JDBC it has Multiple Feauters Like Withdraw Deposite Account Management etc",
    },
    {
      img: img8,
      title: "Nexa E-commerce Hub",
      description:
        "Presenting Nexa E-commerce Hub, a cutting-edge platform leveraging AWS cloud infrastructure, React Bootstrap, Amplify, and S3 for seamless scalability and performance. Combining robust features with intuitive design, it offers a dynamic online shopping experience tailored for modern consumers.",
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-center  mt-5 mb-5">
        <h3 style={{ color: "var(--primary-color)" }} className="mx-auto">
          Projects
        </h3>
        <Link to={"/"} className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            className="cursor-pointer me-4"
            style={{ cursor: "pointer" }}
          >
            <g fill="var(--text-color)" fill-rule="evenodd" clip-rule="evenodd">
              <path d="M5.47 5.47a.75.75 0 0 1 1.06 0l12 12a.75.75 0 1 1-1.06 1.06l-12-12a.75.75 0 0 1 0-1.06" />
              <path d="M18.53 5.47a.75.75 0 0 1 0 1.06l-12 12a.75.75 0 0 1-1.06-1.06l12-12a.75.75 0 0 1 1.06 0" />
            </g>
          </svg>
        </Link>
      </div>
      <Row className="m-2 gx-5" style={{ gap: "10rem" }}>
        <Col sm={12} lg={2}>
          <div
            className="text-white sidepanel mx-auto rounded-1 py-4"
            style={{ width: "20rem" }}
          >
            <Image src={img1} roundedCircle width={50} height={50} />
            <p>P V D SIVA KRISHNA</p>
            <p>BIRTHDAY: 25/07/2003</p>
            <p>
              WEBSITE:<a href="#"></a>
            </p>
            <p>EMAIL:pillashivakrisna6@gmail.com</p>
            <p>PHONE: 7386898469</p>
            <p>Location: Kakinada,Andhra Pradesh</p>
            <Button
              className="btn btn-success"
              onClick={handleDownload}
              disabled={loading}
            >
              {loading ? "Downloading..." : "Download CV"}
            </Button>
            <div className="lang  shadow-md bg-dark rounded mt-4 text-center">
              <p className="text-success fw-bold">Interests</p>
              <p>Problem Solving</p>
              <p>Playing Online Games</p>
            </div>
          </div>
        </Col>

        <Col sm={12} lg={2}>
          <div className="d-flex flex-wrap flex-row projectparent gap-5 flex-start">
            {projectsData.map((project, index) => (
              <div className="projectcard-container" key={index}>
                <div className="projectcard text-white rounded">
                  <div
                    className="projectcard-front text-center "
                    style={{
                      backgroundImage: `url(${project.img})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <span className="text-dark  fw-bold textclass">
                      {project.title}
                    </span>
                  </div>
                  <div
                    className="projectcard-back"
                    style={{ overflow: "scroll" }}
                  >
                    <h5 className="p-2">{project.title}</h5>
                    <p className="p-2">{project.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Projects;
