import { Row, Col } from "react-bootstrap";

import "../Styles/Servicestyles.css";
const Services = () => {
  const ser = [
    {
      head: "Web Development",
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <path
            fill="var(--primary-color)"
            d="M27 2H5C3.344 2 2 3.344 2 5v22c0 1.656 1.344 3 3 3h22c1.656 0 3-1.344 3-3V5c0-1.656-1.344-3-3-3M9.938 24.031a1.248 1.248 0 1 1-2.162-1.25l.894-1.544c1.006-.306 1.831-.069 2.475.712zm8.681-3.368H7.25c-.688 0-1.25-.563-1.25-1.25s.563-1.25 1.25-1.25h3.188l4.087-7.075l-1.281-2.212A1.252 1.252 0 0 1 13.7 7.17a1.252 1.252 0 0 1 1.706.456l.556.962l.556-.962a1.248 1.248 0 1 1 2.162 1.25l-5.362 9.288h3.881c1.262 0 1.969 1.481 1.419 2.5zm6.131 0h-1.813l1.225 2.119c.344.6.137 1.363-.456 1.706c-.6.344-1.363.137-1.706-.456c-2.056-3.556-3.594-6.231-4.625-8.006c-1.044-1.813-.3-3.625.444-4.237c.819 1.419 2.044 3.544 3.681 6.375h3.25a1.25 1.25 0 1 1 0 2.5z"
          />
        </svg>
      ),
      details: "The Frotend React  With Server Next.jS Frame Work.",
    },
    {
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--primary-color)"
            d="M6.5 20q-2.275 0-3.887-1.575T1 14.575q0-1.95 1.175-3.475T5.25 9.15q.625-2.3 2.5-3.725T12 4q2.925 0 4.963 2.038T19 11q1.725.2 2.863 1.488T23 15.5q0 1.875-1.312 3.188T18.5 20z"
          />
        </svg>
      ),
      details: "The Solution Architecture & Cloud Practioner ",
      head: "AWS Cloud",
    },
    {
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 48 48"
        >
          <defs>
            <mask id="ipSThinkingProblem0">
              <g
                fill="var(--primary-color)"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
              >
                <path
                  fill="var(--primary-color)"
                  stroke="#fff"
                  d="m38 21l5 9l-5 1v6h-3l-6-1l-1 7H13l-2-10.381C7.92 29.703 6 25.576 6 21c0-8.837 7.163-16 16-16s16 7.163 16 16"
                />
                <path stroke="#000" d="M17 19a5 5 0 1 1 5 5v3m0 6v1" />
              </g>
            </mask>
          </defs>
          <path
            fill="var(--primary-color)"
            d="M0 0h48v48H0z"
            mask="url(#ipSThinkingProblem0)"
          />
        </svg>
      ),
      details: "Data Structures & Algorithms ",
      head: "Problem Solving",
    },
    {
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--primary-color)"
            d="M7 17h2v-5H7zm8 0h2V7h-2zm-4 0h2v-3h-2zm0-5h2v-2h-2zm-6 9q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21z"
          />
        </svg>
      ),
      details: "Using Python Librarys make interactive dashboard",
      head: "Data Analyze & Visualize",
    },
    {
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--primary-color)"
            d="m10.2 13.2l-1.05-1.05q.2-.275.275-.562T9.5 11q0-.3-.075-.587t-.275-.538L10.2 8.8q.4.475.6 1.05T11 11q0 .575-.2 1.138t-.6 1.062m2.125 2.15l-1.075-1.075q.625-.7.938-1.55T12.5 11q0-.875-.312-1.712T11.25 7.75l1.075-1.075q.85.925 1.263 2.038T14 11q0 1.175-.413 2.3t-1.262 2.05M5 12q-.825 0-1.412-.587T3 10q0-.825.588-1.412T5 8q.825 0 1.413.588T7 10q0 .825-.587 1.413T5 12m-4 4v-.575q0-.6.325-1.1t.9-.75q.65-.275 1.338-.425T5 13q.75 0 1.438.15t1.337.425q.575.25.9.75t.325 1.1V16zm18-4q-.825 0-1.412-.587T17 10q0-.825.588-1.412T19 8q.825 0 1.413.588T21 10q0 .825-.587 1.413T19 12m-4 4v-.575q0-.6.325-1.1t.9-.75q.65-.275 1.337-.425T19 13q.75 0 1.437.15t1.338.425q.575.25.9.75t.325 1.1V16z"
          />
        </svg>
      ),
      details: "English Hindi Telugu",
      head: "Communication",
    },
  ];
  return (
    <>
      <div className="myservices " style={{ color: "var(--text-color)" }}>
        <h4>My Services</h4>
        <Row className="g-5 mt-5">
          {ser.map((item, index) => {
            return (
              <>
                <Col lg={3} md={12}>
                  <div
                    key={index}
                    style={{
                     
                      height: "13rem",
                      border: "3px solid white",
                    }}
                    className="p-4  rounded-3 service d-flex flex-wrap"
                  >
                    {item.logo}
                    <h5 className="p-1">{item.head}</h5>
                    <p className="p-1">{item.details}</p>
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
      </div>
    </>
  );
};
export default Services;
