import { useRef, useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import "../Styles/App.css";
const Home = () => {
  const cardRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const targetCard = entry.target.querySelector(".card");
          targetCard.classList.toggle("show", entry.isIntersecting);
        });
      },
      {
        threshold: 1,
      }
    );

    observer.observe(cardRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <>
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <div
            ref={cardRef}
            className={`card-container ${isVisible ? "show" : ""} slide `}
          >
            <div className="card p-5" style={{ zIndex: "-111" }}>
              <p
                style={{ color: "var(--text-color)", fontSize: "2rem" }}
                className="text-secondary my-3"
              >
                Hi There!
              </p>
              <div className="typewriter me-auto m-">
                <div>
                  <h1 className="text-success">I am Developer...</h1>
                </div>
              </div>
              <p style={{ color: "var(--text-color)" }} className="">
                I make the complex simple.
              </p>
              <Col sm={12}>
                <Button
                  popovertarget="mypopover"
                  popovertargetaction="show"
                  style={{ background: "var(--primary-color)", zIndex:"11111" }}
                  className="border-0 px-4 py-2 my-2 "
                >
                  Contact Me
                </Button>
              </Col>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Home;
