import Footer from "../components/Footer";
import Profile from "../components/Profile";
import Services from "../components/Services";
import "../Styles/App.css";
import Reference from "../components/Reference";
import Home from "../components/Home";
import Navbars from "../components/Navbars";
import Gallery from "../components/Gallery";
import { Row, Col } from "react-bootstrap";
import Parentfooter from "../components/Parentfooter";
const LandPage = () => {
  return (
    <>
      <Navbars />
      <Row>
        <Col lg={6} md={12}>
          <Home />
        </Col>

        <Col lg={6} md={12}>
          <Gallery />
        </Col>
      </Row>
      <Footer />
      <Profile />
      
      <Services />
      <Reference/>
      <Parentfooter/>
    </>
  );
};
export default LandPage;
