import "../Styles/Badgestyle.css";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import LazyLoad from "react-lazy-load";
import pdf1 from "../pdfs/AWS_CP_Certificate.pdf";
import pdf2 from "../pdfs/AWS_Result.pdf";
import pdf3 from "../pdfs/AWS1.pdf";
import pdf4 from "../pdfs/AWS2.pdf";
import pdf5 from "../pdfs/AWS3.pdf";
import pdf6 from "../pdfs/AWS4.pdf";
import pdf7 from "../pdfs/Bajaj.pdf";
import pdf8 from "../pdfs/CodeTantra.pdf";
import pdf9 from "../pdfs/Coursera_Azure_Certificate_2.pdf";
import pdf10 from "../pdfs/Coursera_Azure_Certificate_3.pdf";
import pdf11 from "../pdfs/Coursera_Azure_Certificate_4.pdf";
import pdf12 from "../pdfs/Coursera_Azure_Certificate_5.pdf";
import pdf13 from "../pdfs/Coursera_Azure_Certificate.pdf";
import pdf14 from "../pdfs/Edumoon.pdf";
import pdf15 from "../pdfs/Hackerrank1.pdf";
import pdf16 from "../pdfs/Hackerrank2.pdf";
import pdf17 from "../pdfs/Intership_certificate.pdf";
import pdf18 from "../pdfs/ISRO.pdf";
import pdf19 from "../pdfs/Linkedin1.pdf";
import pdf20 from "../pdfs/Linkedin2.pdf";
import pdf21 from "../pdfs/Linkedin3.pdf";
import pdf22 from "../pdfs/Linkedin4.pdf";
import pdf23 from "../pdfs/Linkedin5.pdf";
import pdf24 from "../pdfs/Linkedin6.pdf";
import pdf25 from "../pdfs/Linkedin7.pdf";
import pdf26 from "../pdfs/Linkedin8.pdf";
import pdf27 from "../pdfs/Linkedin9.pdf";
import pdf28 from "../pdfs/Shaukar_Consultancy_Internship.pdf";
import img1 from "../assets/Badgephoto/aws1.jpg";
import img2 from "../assets/Badgephoto/aws2.jpg";
import img3 from "../assets/Badgephoto/aws3.jpg";
import img4 from "../assets/Badgephoto/aws4.jpg";
import pdf29 from "../pdfs/Internshipletter2.pdf";
const Badge = () => {
  const pdfset = [
    pdf1,
    pdf2,
    pdf3,
    pdf4,
    pdf5,
    pdf6,
    pdf7,
    pdf8,
    pdf9,
    pdf10,
    pdf11,
    pdf12,
    pdf13,
    pdf14,
    pdf15,
    pdf16,
    pdf17,
    pdf18,
    pdf19,
    pdf20,
    pdf21,
    pdf22,
    pdf23,
    pdf24,
    pdf25,
    pdf26,
    pdf27,
    pdf28,
    pdf29,
  ];
  const img = [img1, img2, img3, img4];
  return (
    <>
      <div className="d-flex mt-5 mb-5">
        <h4
          className="text-center mx-auto"
          style={{ color: "var(--text-color)" }}
        >
          Badges & Certifications
        </h4>
        <Link to={"/"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            className="cursor-pointer me-4"
            style={{ cursor: "pointer" }}
          >
            <g fill="var(--text-color)" fill-rule="evenodd" clip-rule="evenodd">
              <path d="M5.47 5.47a.75.75 0 0 1 1.06 0l12 12a.75.75 0 1 1-1.06 1.06l-12-12a.75.75 0 0 1 0-1.06" />
              <path d="M18.53 5.47a.75.75 0 0 1 0 1.06l-12 12a.75.75 0 0 1-1.06-1.06l12-12a.75.75 0 0 1 1.06 0" />
            </g>
          </svg>
        </Link>
      </div>
      <div className="d-flex flex-wrap gap-2 justify-content-center">
        {pdfset.map((pdf, index) => (
          <LazyLoad
            key={`pdf${index}`}
            height={300}
            offset={100}
            threshold={1}
            onContentVisible={() => {
              console.log("loaded!");
            }}
          >
            <iframe
              src={pdf}
              frameBorder={0}
              className="m-1 pdf1"
              style={{ width: "15rem", height: "18rem" }}
              title={`pdf${index}`}
            ></iframe>
          </LazyLoad>
        ))}
      </div>
      <div className="d-flex flex-wrap gap-3 justify-content-center mt-5">
        {img.map((img, index) => {
          return (
            <Image
              src={img}
              className="mb-5 p-4 rounded "
              style={{
                width: "20rem",
                height: "17rem",
                border: "3px solid var(--primary-color)",
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default Badge;
