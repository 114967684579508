import React, { useRef,useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import '../Styles/ContactStyle.css'
import img from '../assets/Reference/AWS-BACK.png'
import { Col, Container, Form ,Image} from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
   const [showForm, setShowForm] = useState(true);
  const form = useRef();
  const handleCrossClick = () => {
    setShowForm(false);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_edh98gd",
        "template_7sdghrm",
        form.current,
        "OeSs-UTNE4yLG7N-4"
      )
      .then(
        (result) => {
          toast.success("Email Sent Successful!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        },
        (error) => {
           toast.error("Email was not deliverd.", {
             position: "top-right",
             autoClose: 3000,
             hideProgressBar: true,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
           });
        }
      );
  };

  return (
    <>
      <div className="contact">
        <Col xs={12} md={12} lg={12} className="">
          <Image className="img img-fluid" src={img} />
        </Col>
        
        <div className={`contact-form ${showForm ? "show" : "hide"}`}>
          <div className="ms-auto me-5 cross" onClick={handleCrossClick}>
            <Link to={"/"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                className="cursor-pointer"
                style={{ cursor: "pointer" }}
              >
                <g
                  fill="var(--text-color)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path d="M5.47 5.47a.75.75 0 0 1 1.06 0l12 12a.75.75 0 1 1-1.06 1.06l-12-12a.75.75 0 0 1 0-1.06" />
                  <path d="M18.53 5.47a.75.75 0 0 1 0 1.06l-12 12a.75.75 0 0 1-1.06-1.06l12-12a.75.75 0 0 1 1.06 0" />
                </g>
              </svg>
            </Link>
          </div>
          <h4
            className="mx-auto mb-4"
            style={{ color: "var(--primary-color)" }}
          >
            Hire Me
          </h4>
          <div className="formcon shadow-lg rounded-4">
            <Form ref={form} onSubmit={sendEmail}>
              <label className="form-label">Name</label>
              <Form.Control type="text" name="user_name" className="w-100" />
              <label>Email</label>
              <Form.Control type="email" name="user_email" />
              <div className="mb-3"></div>
              <label className="form-label mb-2">Message</label>
              <textarea name="message" className="form-control" />
              <div className="my-5"></div>
              <Form.Control
                type="submit"
                value="Send"
                className="text-white"
                style={{ background: "var(--primary-color)" }}
              />
            </Form>
            <ToastContainer />
          </div>
          <h5
            className="me-auto ms-5 mt-5"
            style={{ color: "var(--text-color)" }}
          >
            My Location
          </h5>
          <Col>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61055.27566566965!2d82.20594715160132!3d16.976776996130692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3827ddaf59b4f7%3A0x92f8ddb9c89a4803!2sKakinada%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1705860290750!5m2!1sen!2sin"
              className="map"
              height="250"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </div>
      </div>
    </>
  );
};
export default Contact;