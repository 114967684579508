import "./Styles/App.css";
import Badge from "./components/Badge";
import Home from "./components/Home";
import Navbars from "./components/Navbars";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import Profile from "./components/Profile";
import Services from "./components/Services";
import Contact from "./components/Contact";
import LandPage from "./pages/LandPage";
import Resume from "./components/Resume";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projects from "./components/Projects";
const App = () => {
  return (
    <>
     
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandPage />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Badge" element={<Badge />} />
          <Route path="/Footer" element={<Footer />} />
          <Route path="/Navbars" element={<Navbars />} />
          <Route path="/Resume" element={<Resume />} />
          <Route path="/Projects" element={<Projects/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
