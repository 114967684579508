import "../Styles/App.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const Navbars = () => {
  return (
    <>
      <Navbar
        style={{ zIndex: "1111", background: "black" }}
        collapseOnSelect
        expand="lg"
        className="mt-4"
      >
        <Container>
          <Navbar.Brand
            href="#home"
            style={{ color: "var(--text-color)" }}
            className="d-flex"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 32 32"
            >
              <path
                fill="var(--primary-color)"
                d="m28.307 5.521l-.01 4.438H14.162a.533.533 0 0 0 0 1.067l3.682-.021c5.797 0 10.49 4.698 10.49 10.49c0 5.797-4.693 10.49-10.49 10.49l-14.182.016l.01-4.5h14.177a6.009 6.009 0 0 0 0-12.015l-3.698-.036a4.963 4.963 0 1 1-.005-9.928zM3.682 26.458l-.005-4.443h14.146c.281 0 .51-.234.51-.516a.513.513 0 0 0-.51-.516h-3.661c-5.792 0-10.484-4.698-10.484-10.495c0-5.792 4.693-10.49 10.484-10.49l14.135.005l.01 4.464l-14.141.005c-3.318 0-6 2.693-6 6.01s2.698 6.005 6.021 6.005l3.698.042a4.96 4.96 0 1 1 0 9.922H3.687z"
              />
            </svg>
            <p
              style={{
                textDecoration: "underline",
                textDecorationColor: "var(--primary-color)",
              }}
            >
              HIVA
            </p>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ background: "var(--primary-color)" }}
          />
          <Navbar.Collapse id="responsive-navbar-nav" className="">
            <Nav
              className="ms-auto d-flex gap-4"
              style={{ textDecoration: "none" }}
            >
              <Link
                to={"/Resume"}
                style={{ textDecoration: "none", color: "var(--text-color)" }}
              >
                <Nav.Link
                  className="navitem"
                  href="#pricing"
                  style={{ color: "var(--text-color)" }}
                >
                  Resume
                </Nav.Link>
              </Link>
              <Link
                to={"/Projects"}
                style={{ textDecoration: "none", color: "var(--text-color)" }}
              >
                <Nav.Link
                  href="#pricing"
                  className="navitem"
                  style={{ color: "var(--text-color)" }}
                >
                  Projects
                </Nav.Link>
              </Link>
              <Nav.Link
                className="navitem"
                style={{ color: "var(--text-color)" }}
              >
                <Link
                  to={"/Badge"}
                  style={{ textDecoration: "none", color: "var(--text-color)" }}
                >
                  Badges
                </Link>
              </Nav.Link>

              <Nav.Link
                to="/Contact"
                className="navitem"
                style={{
                  color: "var(--text-color)",
                  textDecoration: "none",
                  listStyle: "none",
                }}
              >
                <Link
                  to="/Contact"
                  style={{ textDecoration: "none", color: "var(--text-color)" }}
                >
                  Hire Me
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Navbars;
