import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Styles/Parentfooterstyle.css";
import { Link } from "react-router-dom";

const ParentFooter = () => {
  return (
    <>
      <h4 className="headtag" style={{ color: "var(--text-color)" }}>
        Contact Info
      </h4>
      <div className="parent-footer">
        <Container className="footer-container mt-5 mb-5">
          <Row>
            <Col md={4} className="col1 text-center">
              <div className="footer-section">
                <h4 style={{ color: "var(--primary-color)" }}>About Us</h4>
                <p style={{ color: "var(--text-color)", textAlign: "justify" }}>
                  I am available 24/7, providing dedicated support and
                  leveraging my passion for cloud technologies to deliver
                  innovative and certified in cloud Technology's,making user
                  interactive web development.
                </p>
              </div>
            </Col>
            <Col md={4} className="col2 text-center">
              <div className="footer-section">
                <h4 style={{ color: "var(--primary-color)" }}>Contact Us</h4>

                <p style={{ color: "var(--text-color)" }}>
                  pillashivakrishna6@gmail.com
                </p>
                <p style={{ color: "var(--text-color)" }}>7386898469</p>
                <p style={{ color: "var(--text-color)" }}>
                  Kakinada | Andhara Pradesh | INDIA
                </p>
              </div>
            </Col>
            <Col md={4} className="text-center">
              <div className="footer-section">
                <h4 style={{ color: "var(--primary-color)" }}>Social Media</h4>
                <div className="d-flex gap-4 justify-content-center mt-5">
                  <Link
                    to={
                      "https://www.linkedin.com/in/p-shiva-krihsna-4a0343282/"
                    }
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 256 256"
                    >
                      <g fill="none">
                        <rect width="256" height="256" fill="#fff" rx="60" />
                        <rect width="256" height="256" fill="#0A66C2" rx="60" />
                        <path
                          fill="#fff"
                          d="M184.715 217.685h29.27a4 4 0 0 0 4-3.999l.015-61.842c0-32.323-6.965-57.168-44.738-57.168c-14.359-.534-27.9 6.868-35.207 19.228a.32.32 0 0 1-.595-.161V101.66a4 4 0 0 0-4-4h-27.777a4 4 0 0 0-4 4v112.02a4 4 0 0 0 4 4h29.268a4 4 0 0 0 4-4v-55.373c0-15.657 2.97-30.82 22.381-30.82c19.135 0 19.383 17.916 19.383 31.834v54.364a4 4 0 0 0 4 4M38 59.628c0 11.864 9.767 21.626 21.632 21.626c11.862-.001 21.623-9.769 21.623-21.631C81.253 47.761 71.491 38 59.628 38C47.762 38 38 47.763 38 59.627m6.959 158.058h29.307a4 4 0 0 0 4-4V101.66a4 4 0 0 0-4-4H44.959a4 4 0 0 0-4 4v112.025a4 4 0 0 0 4 4"
                        />
                      </g>
                    </svg>
                  </Link>
                  {/* logo 2 */}
                  <Link to={"https://github.com/SHIVA-6699"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 256 256"
                    >
                      <g fill="none">
                        <rect width="256" height="256" fill="#242938" rx="60" />
                        <path
                          fill="#fff"
                          d="M128.001 30C72.779 30 28 74.77 28 130.001c0 44.183 28.653 81.667 68.387 94.89c4.997.926 6.832-2.169 6.832-4.81c0-2.385-.093-10.262-.136-18.618c-27.82 6.049-33.69-11.799-33.69-11.799c-4.55-11.559-11.104-14.632-11.104-14.632c-9.073-6.207.684-6.079.684-6.079c10.042.705 15.33 10.305 15.33 10.305c8.919 15.288 23.394 10.868 29.1 8.313c.898-6.464 3.489-10.875 6.349-13.372c-22.211-2.529-45.56-11.104-45.56-49.421c0-10.918 3.906-19.839 10.303-26.842c-1.039-2.519-4.462-12.69.968-26.464c0 0 8.398-2.687 27.508 10.25c7.977-2.215 16.531-3.326 25.03-3.364c8.498.038 17.06 1.149 25.051 3.365c19.087-12.939 27.473-10.25 27.473-10.25c5.443 13.773 2.019 23.945.98 26.463c6.412 7.003 10.292 15.924 10.292 26.842c0 38.409-23.394 46.866-45.662 49.341c3.587 3.104 6.783 9.189 6.783 18.519c0 13.38-.116 24.149-.116 27.443c0 2.661 1.8 5.779 6.869 4.797C199.383 211.64 228 174.169 228 130.001C228 74.771 183.227 30 128.001 30M65.454 172.453c-.22.497-1.002.646-1.714.305c-.726-.326-1.133-1.004-.898-1.502c.215-.512.999-.654 1.722-.311c.727.326 1.141 1.01.89 1.508m4.919 4.389c-.477.443-1.41.237-2.042-.462c-.654-.697-.777-1.629-.293-2.078c.491-.442 1.396-.235 2.051.462c.654.706.782 1.631.284 2.078m3.374 5.616c-.613.426-1.615.027-2.234-.863c-.613-.889-.613-1.955.013-2.383c.621-.427 1.608-.043 2.236.84c.611.904.611 1.971-.015 2.406m5.707 6.504c-.548.604-1.715.442-2.57-.383c-.874-.806-1.118-1.95-.568-2.555c.555-.606 1.729-.435 2.59.383c.868.804 1.133 1.957.548 2.555m7.376 2.195c-.242.784-1.366 1.14-2.499.807c-1.13-.343-1.871-1.26-1.642-2.052c.235-.788 1.364-1.159 2.505-.803c1.13.341 1.871 1.252 1.636 2.048m8.394.932c.028.824-.932 1.508-2.121 1.523c-1.196.027-2.163-.641-2.176-1.452c0-.833.939-1.51 2.134-1.53c1.19-.023 2.163.639 2.163 1.459m8.246-.316c.143.804-.683 1.631-1.864 1.851c-1.161.212-2.236-.285-2.383-1.083c-.144-.825.697-1.651 1.856-1.865c1.183-.205 2.241.279 2.391 1.097"
                        />
                      </g>
                    </svg>
                  </Link>
                  {/* logo 3 */}
                  <Link to={"https://leetcode.com/SHIVA-6699/"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="orange"
                        d="M13.483 0a1.374 1.374 0 0 0-.961.438L7.116 6.226l-3.854 4.126a5.266 5.266 0 0 0-1.209 2.104a5.35 5.35 0 0 0-.125.513a5.527 5.527 0 0 0 .062 2.362a5.83 5.83 0 0 0 .349 1.017a5.938 5.938 0 0 0 1.271 1.818l4.277 4.193l.039.038c2.248 2.165 5.852 2.133 8.063-.074l2.396-2.392c.54-.54.54-1.414.003-1.955a1.378 1.378 0 0 0-1.951-.003l-2.396 2.392a3.021 3.021 0 0 1-4.205.038l-.02-.019l-4.276-4.193c-.652-.64-.972-1.469-.948-2.263a2.68 2.68 0 0 1 .066-.523a2.545 2.545 0 0 1 .619-1.164L9.13 8.114c1.058-1.134 3.204-1.27 4.43-.278l3.501 2.831c.593.48 1.461.387 1.94-.207a1.384 1.384 0 0 0-.207-1.943l-3.5-2.831c-.8-.647-1.766-1.045-2.774-1.202l2.015-2.158A1.384 1.384 0 0 0 13.483 0m-2.866 12.815a1.38 1.38 0 0 0-1.38 1.382a1.38 1.38 0 0 0 1.38 1.382H20.79a1.38 1.38 0 0 0 1.38-1.382a1.38 1.38 0 0 0-1.38-1.382z"
                      />
                    </svg>
                  </Link>
                  {/* logo 4 */}
                  <Link to={"https://twitter.com/Shivakr17540081"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                    >
                      <g fill="white">
                        <path d="M1 2h2.5L3.5 2h-2.5z">
                          <animate
                            fill="freeze"
                            attributeName="d"
                            dur="0.4s"
                            values="M1 2h2.5L3.5 2h-2.5z;M1 2h2.5L18.5 22h-2.5z"
                          />
                        </path>
                        <path d="M5.5 2h2.5L7.2 2h-2.5z">
                          <animate
                            fill="freeze"
                            attributeName="d"
                            dur="0.4s"
                            values="M5.5 2h2.5L7.2 2h-2.5z;M5.5 2h2.5L23 22h-2.5z"
                          />
                        </path>
                        <path d="M3 2h5v0h-5z" opacity="0">
                          <set attributeName="opacity" begin="0.4s" to="1" />
                          <animate
                            fill="freeze"
                            attributeName="d"
                            begin="0.4s"
                            dur="0.4s"
                            values="M3 2h5v0h-5z;M3 2h5v2h-5z"
                          />
                        </path>
                        <path d="M16 22h5v0h-5z" opacity="0">
                          <set attributeName="opacity" begin="0.4s" to="1" />
                          <animate
                            fill="freeze"
                            attributeName="d"
                            begin="0.4s"
                            dur="0.4s"
                            values="M16 22h5v0h-5z;M16 22h5v-2h-5z"
                          />
                        </path>
                        <path d="M18.5 2h3.5L22 2h-3.5z" opacity="0">
                          <set attributeName="opacity" begin="0.5s" to="1" />
                          <animate
                            fill="freeze"
                            attributeName="d"
                            begin="0.5s"
                            dur="0.4s"
                            values="M18.5 2h3.5L22 2h-3.5z;M18.5 2h3.5L5 22h-3.5z"
                          />
                        </path>
                      </g>
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ParentFooter;
