import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import "../Styles/Reference.css";
import img1 from "../assets/Reference/Reference_1.jpg";
import img2 from "../assets/Reference/Reference_2.png";

const Reference = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    { img: img1, name: "Dr. Hema K. Yarnagula" },
    {
      img: img2,
      name: "Dr. Manasi Sahukar",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === images.length - 1 ? 0 : prevImage + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <div className="ref my-5">
        <h4 style={{ color: "var(--text-color)" }} className="mb-5">
          Reference
        </h4>
        <div className="images rounded d-flex flex-column justify-content-center align-items-center">
          <Image
            src={images[currentImage].img}
            roundedCircle
            fluid
            width={50}
            height={50}
            className="my-2"
          />
          <h6 style={{ color: "var(--primary-color)" }} className="fw-bold">
            {images[currentImage].name}
          </h6>
        </div>
      </div>
    </>
  );
};

export default Reference;
